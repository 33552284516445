import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { Redirect } from 'react-router-dom';

import { withStyles } from '@material-ui/core';

import Assignment from '@material-ui/icons/Assignment';
import Search from '@material-ui/icons/Search';

// core components
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardIcon from '../../components/Card/CardIcon';
import CardHeader from '../../components/Card/CardHeader';
import Button from '../../components/CustomButtons/Button';

import { driverHistory } from '../../requests/requests';
import { cardTitle } from '../../assets/jss/material-dashboard-pro-react';
import { formatTimeDate } from '../../helpers/functions';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  }
};

class DriverHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      table: {
        base: [],
        pages: '',
        loading: false
      },
      goToRide: false
    };
  }

  goToRide = (evt, ride) => {
    evt.preventDefault();
    sessionStorage.setItem('ride', JSON.stringify(ride));
    this.setState({
      goToRide: <Redirect to="/ride" />
    });
  };

  render() {
    const { classes } = this.props;
    const { table, goToRide } = this.state;

    if (goToRide) return goToRide;

    return (
      <GridContainer justify="center">
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Corridas</h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                className="-striped -highlight"
                data={table.base}
                sortable={false}
                defaultPageSize={10}
                pages={table.pages}
                loading={table.loading}
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando..."
                noDataText="Nenhum dado encontrado"
                manual
                onFetchData={state => {
                  this.setState(prevState => ({ table: { ...prevState.table, loading: true } }));

                  driverHistory(state.page * state.pageSize, state.pageSize)
                    .then(res => {
                      res.data.rides.forEach(ride => {
                        ride.btn = (
                          <div className="actions-right" style={{ float: 'right' }}>
                            <Button
                              justIcon
                              round
                              simple
                              color="info"
                              onClick={evt => {
                                this.goToRide(evt, ride);
                              }}
                            >
                              <Search />
                            </Button>
                          </div>
                        );
                      });
                      this.setState(prevState => ({
                        table: {
                          ...prevState.table,
                          base: res.data.rides,
                          loading: false,
                          pages: parseInt(res.data.count / state.pageSize, 10)
                        }
                      }));
                    })
                    .catch(() => {
                      this.setState(prevState => ({
                        table: {
                          ...prevState.table,
                          loading: false
                        }
                      }));
                    });
                }}
                columns={[
                  {
                    Header: 'Destino',
                    accessor: 'destinationAddress',
                    width: 200
                  },
                  {
                    id: 'colDistance',
                    Header: 'Distancia',
                    accessor: row => `${(row.distance / 1000).toFixed(1).replace('.', ',')} km`,
                    width: 100
                  },
                  {
                    id: 'colStatus',
                    Header: 'Status',
                    accessor: row => row.lastStatus.name,
                    width: 100
                  },
                  {
                    id: 'colCreatedAt',
                    Header: '',
                    accessor: row =>
                      `${formatTimeDate(row.lastStatus.created_at)[0]} ${
                        formatTimeDate(row.lastStatus.created_at)[1]
                      }`
                  },
                  {
                    id: 'colBtn',
                    Header: '',
                    accessor: row => row.btn,
                    sortable: false,
                    filterable: false
                  }
                ]}
                showPageJump={false}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

DriverHistory.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DriverHistory);
