import React, { Component } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Car from '@material-ui/icons/CallMergeTwoTone';

import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import Timeline from '../../components/Timeline/Timeline';

import { formatTimeDate, formatMoney } from '../../helpers/functions';
import { getRideMap } from '../../requests/requests';

import cardImagesStyles from '../../assets/jss/material-dashboard-pro-react/cardImagesStyles';

const bdgColorHelper = statusId => {
  switch (statusId) {
    case 1:
      return 'info';
    case 2:
      return 'info';
    case 3:
      return 'warning';
    case 4:
      return 'success';
    case 5:
      return 'danger';
    case 6:
      return 'success';
    case 7:
      return 'warning';
    case 8:
      return 'danger';
    case 9:
      return 'danger';
    default:
      return 'danger';
  }
};

class Ride extends Component {
  constructor(props) {
    super(props);

    const ride = JSON.parse(sessionStorage.getItem('ride'));
    this.state = {
      ride,
      status: ride.status.map(item => ({
        inverted: item.status_id % 2 === 0,
        badgeColor: bdgColorHelper(item.status_id),
        badgeIcon: Car,
        title: item.name,
        titleColor: bdgColorHelper(item.status_id),
        body: (
          <div>
            <b>Data: </b>
            {`${formatTimeDate(item.created_at)[0]}`}
            <br />
            <b>Hora: </b>
            {`${formatTimeDate(item.created_at)[1]}`}
            <br />
          </div>
        )
      }))
    };
  }

  getDriverAmount = () => {
    const { ride } = this.state;

    if (
      ride.payments != null &&
      ride.payments.length > 0 &&
      ride.payments[0].driver_amount != null
    ) {
      const driverPart = (100 - ride.payments[0].driver_amount.commission) / 100.0;
      const driverAmount = driverPart * ride.payments[0].driver_amount.amount;

      return driverAmount;
    }

    return null;
  };

  componentWillUnmount() {
    sessionStorage.removeItem('ride');
  }

  render() {
    const { classes } = this.props;
    const { ride, status } = this.state;

    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={6}>
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <img
                  className={classes.cardImgTop}
                  data-src="holder.js/100px180/"
                  alt="Mapa"
                  style={{ height: '100%', width: '100%', display: 'block' }}
                  src={ride.map}
                  data-holder-rendered="true"
                />
                <CardBody>
                  <h3>Informações adicionais</h3>                  
                  <hr />
                  <p>
                    <strong>{`${(ride.distance / 1000).toFixed(1).replace('.', ',')} km`}</strong>
                    <br />
                    <br />
                    <strong>Partindo de: </strong>
                    {ride.originAddress}
                    <br />
                    <br />
                    <strong>Destino: </strong>
                    {ride.destinationAddress}
                    <br />
                    {ride.fare.name}
                    <br />
                    <br />
                    <strong>Cliente: </strong>
                    {ride.requester.business ? ride.requester.business.name : ride.requester.name}
                  </p>
                  <hr />
                  {ride.measures ? (
                    <p>
                      {`Pacote ${ride.measures.name}`}
                      <br />
                      {ride.measures.note}
                    </p>
                  ) : (
                    ''
                  )}
                  <hr />
                  {/* <h6>Preços</h6> */}
                  <p>
                    <strong>{`Ganho aproximado: R$ ${formatMoney(ride.price.driver_price)}`}</strong>
                  </p>                  
                  <hr />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={8} md={6}>
          <GridContainer>
            <GridItem xs={12}>
              <Card plain>
                <CardBody plain>
                  <Timeline stories={status} />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(cardImagesStyles)(Ride);
