import React from 'react';
import PropTypes from 'prop-types';

export default function InputError({ message }) {
  return <div style={{ color: 'red', fontSize: 12 }}>{message}</div>;
}

InputError.propTypes = {
  message: PropTypes.string.isRequired
};
