// @material-ui/icons
import DashboardIcon from '@material-ui/icons/Dashboard';
import Person from '@material-ui/icons/Person';
import History from '@material-ui/icons/History';
import ExitToApp from '@material-ui/icons/ExitToAppOutlined';

import UserProfile from 'views/Pages/UserProfile.jsx';
import DriverDashboard from 'views/Pages/DriverDashboard';
import DriverHistory from '../views/Tables/DriverHistory';
import Ride from '../views/Pages/Ride';

import UpdatePassword from '../views/Pages/UpdatePassword';
import LoginPage from '../views/Pages/LoginPage';
import DriverTransfers from '../views/Tables/DriverTransfers';

const dashRoutes = [
  {
    path: '/driver-dashboard',
    name: 'Dashboard',
    icon: DashboardIcon,
    component: DriverDashboard,
    hidden: false
  },
  {
    path: '/user-page',
    name: 'Minha conta',
    icon: Person,
    component: UserProfile,
    hidden: true
  },
  {
    path: '/driver-history',
    name: 'Histórico',
    icon: History,
    component: DriverHistory
  },
  {
    path: '/driver-transfers',
    name: 'Repasses',
    icon: History,
    component: DriverTransfers
  },
  {
    path: '/ride',
    name: 'Corrida',
    icon: DashboardIcon,
    component: Ride,
    hidden: true
  },
  {
    path: '/update-password',
    name: 'Atualizar Senha',
    icon: DashboardIcon,
    component: UpdatePassword,
    hidden: true
  },
  { path: '/driver/login', name: 'Sair', icon: ExitToApp, component: LoginPage },
  { redirect: true, path: '/', pathTo: '/driver-history', name: 'Historico' }
];
export default dashRoutes;
