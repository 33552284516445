import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import PagesHeader from 'components/Header/PagesHeader.jsx';
import Footer from 'components/Footer/Footer.jsx';

import pagesRoutes from 'routes/pages.jsx';

import pagesStyle from 'assets/jss/material-dashboard-pro-react/layouts/pagesStyle.jsx';

import bgImage from 'assets/img/register.jpeg';
import { PermissibleRender } from '@brainhubeu/react-permissible';
import { getPermissions } from '../helpers/functions';

class Pages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      permissions: null
    };
  }

  componentDidMount() {
    document.body.style.overflow = 'unset';
    getPermissions()
      .then(response => {
        this.setState({ permissions: response });
      })
      .catch(error => {
        this.setState({ permissions: error });
      });
  }

  getRoute = () => {
    // if (this.props.location.pathname === '/driver/login') {
    //   return <LoginPage />;
    // }
    return <Redirect to="/driver/login" />;
  };

  render() {
    const { classes, ...rest } = this.props;
    const { permissions } = this.state;

    return (
      <div>
        <PagesHeader {...rest} />
        <div className={classes.wrapper} ref="wrapper">
          <div className={classes.fullPage} style={{ backgroundImage: `url(${bgImage})` }}>
            <Switch>
              {pagesRoutes.map((prop, key) => {
                // if (prop.redirect) {
                //   return (
                //     <Redirect from={prop.path} to={prop.pathTo} key={key} />
                //   );
                // }
                if (prop.locked) {
                  const Component = prop.component;
                  return (
                    <Route
                      path={prop.path}
                      component={() => {
                        return (
                          <PermissibleRender
                            userPermissions={permissions}
                            requiredPermissions={prop.permissions}
                            renderOtherwise={<Redirect to="/driver/login" />}
                          >
                            <Component />
                          </PermissibleRender>
                        );
                      }}
                      key={key}
                    />
                  );
                }

                return <Route path={prop.path} component={prop.component} key={key} />;
              })}
            </Switch>
            <Footer white />
          </div>
        </div>
      </div>
    );
  }
}

Pages.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(pagesStyle)(Pages);
