import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/icons

// core components
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Button from '../../components/CustomButtons/Button';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';

import registerPageStyle from '../../assets/jss/material-dashboard-pro-react/views/registerPageStyle';

class Terms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      goToNext: false,
      goBack: false
    };
  }

  // commit test

  handleAccept = evt => {
    evt.preventDefault();

    this.setState({
      goToNext: true
    });
  };

  handleReject = evt => {
    evt.preventDefault();

    this.setState({
      goBack: true
    });
  };

  render() {
    const { classes } = this.props;
    const { request } = this.props.location.state;
    const { goToNext, goBack } = this.state;

    if (goToNext) {
      return <Redirect to={{ pathname: '/driver/form', state: { request } }} />;
    }

    if (goBack) {
      return <Redirect to="/driver/register" />;
    }

    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card className={classes.cardSignup}>
              <h2 className={classes.cardTitle}>Aceite de termos</h2>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem
                    xs={12}
                    sm={12}
                    md={10}
                    align="justify"
                    style={{ overflowY: 'scroll', height: '500px' }}
                  >
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                      incididunt ut labore et dolore magna aliqua. Ultricies mi quis hendrerit dolor
                      magna eget est lorem ipsum. Eget velit aliquet sagittis id consectetur purus
                      ut faucibus pulvinar. Massa vitae tortor condimentum lacinia quis. Magna eget
                      est lorem ipsum dolor sit. Nunc scelerisque viverra mauris in aliquam sem
                      fringilla ut. Elit sed vulputate mi sit amet mauris. Quis imperdiet massa
                      tincidunt nunc pulvinar sapien. Ultrices mi tempus imperdiet nulla malesuada
                      pellentesque elit eget gravida. In egestas erat imperdiet sed euismod.
                      Malesuada proin libero nunc consequat interdum varius sit. Sem fringilla ut
                      morbi tincidunt. Diam maecenas sed enim ut. Vitae turpis massa sed elementum
                      tempus. Dui ut ornare lectus sit. Suspendisse in est ante in nibh mauris
                      cursus mattis. Nisl nunc mi ipsum faucibus vitae aliquet. Elementum sagittis
                      vitae et leo duis ut diam quam. Elit scelerisque mauris pellentesque pulvinar
                      pellentesque. Blandit libero volutpat sed cras ornare. Risus ultricies
                      tristique nulla aliquet enim tortor at. Cursus euismod quis viverra nibh cras
                      pulvinar mattis nunc sed. Mauris pharetra et ultrices neque ornare aenean
                      euismod elementum. Ac turpis egestas sed tempus urna et pharetra pharetra
                      massa. At volutpat diam ut venenatis tellus. Tincidunt ornare massa eget
                      egestas. Venenatis a condimentum vitae sapien pellentesque. Sed libero enim
                      sed faucibus turpis in eu mi. Amet consectetur adipiscing elit pellentesque
                      habitant morbi tristique senectus et. Sit amet purus gravida quis blandit
                      turpis. Pharetra convallis posuere morbi leo urna molestie at elementum eu.
                      Adipiscing enim eu turpis egestas pretium aenean. Tempus imperdiet nulla
                      malesuada pellentesque elit eget gravida. Adipiscing diam donec adipiscing
                      tristique risus nec. Sit amet aliquam id diam maecenas ultricies mi eget.
                      Lobortis scelerisque fermentum dui faucibus. Ac tincidunt vitae semper quis
                      lectus nulla at volutpat diam. Ullamcorper morbi tincidunt ornare massa eget
                      egestas. Eget arcu dictum varius duis at consectetur lorem donec. Amet purus
                      gravida quis blandit turpis cursus. Fermentum odio eu feugiat pretium nibh
                      ipsum consequat. Enim eu turpis egestas pretium aenean pharetra magna.
                      Curabitur vitae nunc sed velit dignissim sodales ut eu. Nisl tincidunt eget
                      nullam non nisi est sit amet. Dignissim sodales ut eu sem integer vitae justo
                      eget. Dictum varius duis at consectetur lorem. Diam ut venenatis tellus in
                      metus vulputate eu. Non pulvinar neque laoreet suspendisse interdum
                      consectetur libero id faucibus. Lobortis elementum nibh tellus molestie nunc
                      non blandit. Non sodales neque sodales ut etiam sit amet nisl purus. Odio ut
                      enim blandit volutpat. Dui ut ornare lectus sit. Arcu cursus vitae congue
                      mauris. Et ultrices neque ornare aenean euismod elementum nisi quis eleifend.
                      Eu feugiat pretium nibh ipsum. Vitae nunc sed velit dignissim sodales. Lectus
                      vestibulum mattis ullamcorper velit sed ullamcorper morbi. Donec enim diam
                      vulputate ut pharetra. Sed euismod nisi porta lorem mollis aliquam ut. Risus
                      sed vulputate odio ut enim blandit volutpat maecenas. Sed egestas egestas
                      fringilla phasellus faucibus scelerisque. Libero volutpat sed cras ornare arcu
                      dui vivamus arcu felis. Lectus nulla at volutpat diam. Dolor sed viverra ipsum
                      nunc aliquet bibendum enim facilisis gravida. Amet venenatis urna cursus eget
                      nunc scelerisque viverra mauris. Ac tincidunt vitae semper quis lectus nulla.
                      Ut diam quam nulla porttitor massa id. Scelerisque in dictum non consectetur.
                      A erat nam at lectus urna duis convallis. A condimentum vitae sapien
                      pellentesque habitant morbi. Vestibulum sed arcu non odio euismod lacinia at.
                      Arcu bibendum at varius vel pharetra vel turpis. Eu volutpat odio facilisis
                      mauris sit. Mi bibendum neque egestas congue. Faucibus in ornare quam viverra
                      orci sagittis. Aenean euismod elementum nisi quis eleifend quam adipiscing
                      vitae proin. Volutpat odio facilisis mauris sit amet massa. Convallis
                      convallis tellus id interdum velit. Consequat id porta nibh venenatis cras sed
                      felis eget.
                    </p>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={10}>
                    <div className={classes.center}>
                      <Button round color="danger" onClick={this.handleReject}>
                        Rejeito
                      </Button>
                      <Button round color="primary" onClick={this.handleAccept}>
                        Aceito
                      </Button>
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Terms.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(registerPageStyle)(Terms);
