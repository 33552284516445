import React from 'react';
import PropTypes from 'prop-types';
import ImageUploader from 'react-images-upload';
import ClipLoader from 'react-spinners/ClipLoader';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Divider from '@material-ui/core/Divider';

// @material-ui/icons
import DirectionsCar from '@material-ui/icons/DirectionsCar';

// core components
import { Redirect } from 'react-router-dom';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Button from '../../components/CustomButtons/Button';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import InfoArea from '../../components/InfoArea/InfoArea';

import registerPageStyle from '../../assets/jss/material-dashboard-pro-react/views/registerPageStyle';
import { sendImage, identify, driverDocuments } from '../../requests/requests';

import Landing from './Landing';
import Checked from '../../assets/img/checked.png';

const loadingStyle = {
  marginTop: '10%'
};

class DriverDocs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: {
        profile: '',
        selfie: '',
        cnh: '',
        crlv: ''
      },
      responses: {
        profile: false,
        selfie: false,
        cnh: false,
        crlv: false
      },
      loading: false,

      hasSelfie: false,
      hasCnh: false,
      hasCrlv: false,

      isApproved: false,
      isReproved: false
    };
  }

  async componentDidMount() {
    // FIXME: catch e if 200
    try {
      const identifyRes = await identify();

      try {
        const driverDocumentsRes = await driverDocuments();

        if (identifyRes.status === 200) {
          localStorage.setItem('user', JSON.stringify(identifyRes.data));
          this.setState({
            isApproved:
              identifyRes.data.driver != null && identifyRes.data.driver.approved_at != null
          });
          this.setState({
            isReproved:
              identifyRes.data.driver != null && identifyRes.data.driver.reproved_at != null
          });
        }

        if (driverDocumentsRes.status === 200) {
          this.setState({ hasSelfie: driverDocumentsRes.data.selfie != null });
          this.setState({ hasCnh: driverDocumentsRes.data.cnh != null });
          this.setState({ hasCrlv: driverDocumentsRes.data.vehicle_document != null });
        }
      } catch (driverDocumentsError) {
        console.log(driverDocumentsError);
      }
    } catch (identifyError) {
      console.log(identifyError);
      localStorage.removeItem('token');
    }
  }

  handleChange = (id, file) => {
    this.setState(prevState => ({
      images: {
        ...prevState.images,
        [id]: file
      }
    }));
  };

  handleSubmit = async evt => {
    evt.preventDefault();
    const { images } = this.state;
    this.setState({ loading: true });
    try {
      if (!this.state.hasSelfie && this.state.images.selfie) {
        await sendImage('selfie', images.selfie[0]);
        this.setState({ hasSelfie: true });
      }

      if (!this.state.hasCnh && this.state.images.cnh) {
        await sendImage('cnh', images.cnh[0]);
        this.setState({ hasCnh: true });
      }

      if (!this.state.hasCrlv && this.state.images.crlv) {
        await sendImage('crlv', images.crlv[0]);
        this.setState({ hasCrlv: true });
      }
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
    }

    this.setState({ loading: false });
    sessionStorage.clear();
  };

  render() {
    const { classes } = this.props;
    const { responses, loading, hasCnh, hasCrlv, hasSelfie, isApproved, isReproved } = this.state;
    // console.log(responses);

    if (isApproved) {
      return <Redirect to="/dashboard" />;
    }
    if (isReproved) {
      return <Landing txt="Conta não aprovada." />;
    }
    if (hasCnh && hasCrlv && hasSelfie) {
      return <Landing txt="Cadastro em análise" />;
    }

    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card className={classes.cardSignup}>
              <h2 className={classes.cardTitle}>Envio de Documentos</h2>
              <CardBody>
                <form
                  onSubmit={evt => {
                    this.handleSubmit(evt);
                  }}
                  onInvalid={this.handleError}
                >
                  <GridContainer justify="center">
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={6} md={5}>
                        <InfoArea
                          title="Selfie"
                          icon={DirectionsCar}
                          description="Selecione uma foto do seu rosto. Por favor tire a foto em um local com boa iluminação e não utilize máscaras ou óculos."
                          iconColor="rose"
                        />
                        {!this.state.hasSelfie ? (
                          <ImageUploader
                            withIcon={false}
                            withPreview
                            buttonText="Selecionar arquivo"
                            label="Máximo 2mb (apenas .jpg, .png, .jpeg, .pdf)"
                            onChange={file => {
                              this.handleChange('selfie', file);
                            }}
                            imgExtension={['.jpg', '.png', '.jpeg', '.pdf']}
                            maxFileSize={2242880}
                            accept="image/jgp, image/jpeg, image/png, application/pdf"
                          />
                        ) : (
                          <p style={{ textAlign: 'center', padding: '20px' }}>
                            Documento já enviado!
                          </p>
                        )}
                      </GridItem>
                      <GridItem align="center" style={loadingStyle} xs={12} sm={6} md={5}>
                        {loading && !this.state.hasSelfie && this.state.images.selfie ? (
                          <ClipLoader
                            sizeUnit="px"
                            size={100}
                            color="#123abc"
                            loading={!responses.selfie}
                          />
                        ) : (
                          ''
                        )}
                        {responses.selfie ? <img src={Checked} alt="envio concluido" /> : ''}
                      </GridItem>
                    </GridContainer>
                    <Divider style={{ width: '100%' }} />
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={6} md={5}>
                        <InfoArea
                          title="CNH"
                          icon={DirectionsCar}
                          description="Envie uma foto ou digitalização da sua carteira de motorista aberta."
                          iconColor="rose"
                        />
                        {!this.state.hasCnh ? (
                          <ImageUploader
                            withIcon={false}
                            withPreview
                            buttonText="Selecionar arquivo"
                            label="Máximo 2mb (apenas .jpg, .png, .jpeg, .pdf)"
                            onChange={file => {
                              this.handleChange('cnh', file);
                            }}
                            imgExtension={['.jpg', '.png', '.jpeg', '.pdf']}
                            maxFileSize={2242880}
                            accept="image/jgp, image/jpeg, image/png, application/pdf"
                          />
                        ) : (
                          <p style={{ textAlign: 'center', padding: '20px' }}>
                            Documento já enviado!
                          </p>
                        )}
                      </GridItem>
                      <GridItem align="center" style={loadingStyle} xs={12} sm={6} md={5}>
                        {loading && !this.state.hasCnh && this.state.images.cnh ? (
                          <ClipLoader
                            sizeUnit="px"
                            size={100}
                            color="#123abc"
                            loading={!responses.cnh}
                          />
                        ) : (
                          ''
                        )}
                        {responses.cnh ? <img src={Checked} alt="envio concluido" /> : ''}
                      </GridItem>
                    </GridContainer>
                    <Divider style={{ width: '100%' }} />
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={6} md={5}>
                        <InfoArea
                          title="CRLV"
                          icon={DirectionsCar}
                          description="Envie uma foto do documento aberto do seu veículo."
                          iconColor="rose"
                        />
                        {!this.state.hasCrlv ? (
                          <ImageUploader
                            id="crlv"
                            withIcon={false}
                            withPreview
                            buttonText="Selecionar arquivo"
                            label="Máximo 2mb (apenas .jpg, .png, .jpeg, .pdf)"
                            onChange={file => {
                              this.handleChange('crlv', file);
                            }}
                            imgExtension={['.jpg', '.png', '.jpeg', '.pdf']}
                            maxFileSize={2242880}
                            accept="image/jgp, image/jpeg, image/png, application/pdf"
                          />
                        ) : (
                          <p style={{ textAlign: 'center', padding: '20px' }}>
                            Documento já enviado!
                          </p>
                        )}
                      </GridItem>
                      <GridItem align="center" style={loadingStyle} xs={12} sm={6} md={5}>
                        {loading && !this.state.hasCrlv && this.state.images.crlv ? (
                          <ClipLoader
                            sizeUnit="px"
                            size={100}
                            color="#123abc"
                            loading={!responses.crlv}
                          />
                        ) : (
                          ''
                        )}
                        {responses.crlv ? <img src={Checked} alt="envio concluido" /> : ''}
                      </GridItem>
                    </GridContainer>
                    <Divider variant="inset" style={{ width: '100%' }} />
                    <GridContainer justify="center">
                      <GridItem align="center" style={loadingStyle} xs={12} sm={6} md={5}>
                        {loading && !this.state.hasRecords && this.state.images.records ? (
                          <ClipLoader
                            sizeUnit="px"
                            size={100}
                            color="#123abc"
                            loading={!responses.records}
                          />
                        ) : (
                          ''
                        )}
                        {responses.records ? <img src={Checked} alt="envio concluido" /> : ''}
                      </GridItem>
                    </GridContainer>
                    <Button round color="info" type="submit">
                      Enviar
                    </Button>
                  </GridContainer>
                </form>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

DriverDocs.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(registerPageStyle)(DriverDocs);
