import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router-dom';
import Dashboard from './layouts/Dashboard';
import Pages from './layouts/Pages';
// import indexRoutes from "routes/index.jsx";

import 'assets/scss/material-dashboard-pro-react.css';
import Error from './views/Pages/Error';
import Track from './views/Pages/Track';

require('dotenv').config();

const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/driver" component={Pages} />;
      <Route path="/error" component={Error} />;
      <Route path="/track/:id" component={Track} />;
      <Route path="/" component={Dashboard} />;
    </Switch>
  </Router>,
  document.getElementById('root')
);
