import { verifyCPF } from '../helpers/functions';

/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */

/**
 *
 *
 * @export
 * @param {Object} values Values of inputs
 * @param {Object} errors OBject for saving errors
 * @param {string} name Name of the value
 * @returns
 */
export function validateRequired(values, errors, name) {
  if (!values[name]) {
    errors[name] = 'Campo obrigatório';
    return false;
  }
  return true;
}

/**
 *
 *
 * @export
 * @param {Object} values
 * @param {Object} errors
 * @param {RegExp} regex
 * @param {string} name
 * @returns
 */
export function validateRegex(values, errors, regex, name) {
  if (!regex.test(values[name])) {
    errors[name] = 'Formato inválido';
    return false;
  }
  return true;
}

/**
 *
 *
 * @export
 * @param {*} values
 * @param {*} errors
 * @param {number} threshold Max length of the value
 * @param {string} name
 * @returns
 */
export function validateMaxLength(values, errors, threshold, name) {
  if (values[name].length > threshold) {
    errors[name] = `Máximo ${threshold} caracteres`;
    return false;
  }
  return true;
}

export function validateNotSelected(values, errors, name) {
  if (values[name] === undefined) {
    errors[name] = 'Escolha uma opção';
    return false;
  }
  return true;
}

export function validateCPF(values, errors, name) {
  if (!verifyCPF(values[name].replace(/[.-]/g, ''))) {
    errors[name] = 'CPF inválido';
    return false;
  }
  return true;
}
