function statusTxt(response) {
  let mensagem = '';
  const errosLista = [
    {
      nome: 'EMAIL_ALREADY_REGISTERED',
      mensagem: 'Email já registrado; '
    },
    {
      nome: 'EMAIL_ALREADY_TAKEN',
      mensagem: 'Email já cadastrado; '
    },
    {
      nome: 'USER_WITHOUT_COMPANY',
      mensagem: 'Usuario sem copanhia; '
    },
    {
      nome: 'PRODUCT_ALREADY_REGISTERED',
      mensagem: 'Produto já resgistrado; '
    },
    {
      nome: 'ERROR_CREATING_PRODUCT',
      mensagem: 'Erro ao criar o produto; '
    },
    {
      nome: 'error_creating_version',
      mensagem: 'Erro ao criar versão; '
    },
    {
      nome: 'CNPJ_ALREADY_REGISTERED',
      mensagem: 'CNPJ já registrado'
    },
    {
      nome: 'INVALID_CNPJ',
      mensagem: 'CNPJ inválido'
    },
    {
      nome: 'PRODUCT_NOT_FOUND',
      mensagem: 'Produto não encontrado'
    },
    {
      nome: 'PRODUCT_VERSION_NOT_FOUND',
      mensagem: 'Versão do produto não encontrada'
    },
    {
      nome: 'PRODUCT_WITH_ONLY_ONE_ACTIVE_VERSION',
      mensagem: 'Produto somento com uma versão ativa'
    },
    {
      nome: 'DUPLICATE_TAX_DOCUMENT',
      mensagem: 'Cpf já cadastrado'
    },
    {
      nome: 'CANNOT_CHANGE_YET',
      mensagem: 'Você ainda não pode alterar essa configuração.'
    }
  ];

  errosLista.forEach(error => {
    if (error.nome === response.data.error) {
      mensagem = error.mensagem;
    }
  });
  if (mensagem === '') mensagem = 'Ocorreu um erro, tente novamente';

  return mensagem;
}

function catchingErr(err) {
  // Error
  if (err.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    // console.log(error.response.data);
    // console.log(error.response.status);
    // console.log(error.response.headers);
    if (err.response.status === 400) {
      let strError = '';
      err.response.data.errors.forEach(error => {
        strError = error.toString();
      });
      return strError;
    }

    if (err.response.status === 401) {
      return 'Login e/ou senha inválido(s)';
    }

    if (err.response.status === 422) {
      return statusTxt(err.response);
    }

    return err.response.status;
  }

  if (err.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser
    return 'Sem resposta';
  }

  // Something happened in setting up the request that triggered an Error
  return 'request';
}

export default catchingErr;
