import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';

// @material-ui/icons
import Email from '@material-ui/icons/Email';
import Error from '@material-ui/icons/Error';
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import { Formik, ErrorMessage } from 'formik';
import { TextField } from '@material-ui/core';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Button from '../../components/CustomButtons/Button';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import CardFooter from '../../components/Card/CardFooter';
import Snackbar from '../../components/Snackbar/Snackbar';

import loginPageStyle from '../../assets/jss/material-dashboard-pro-react/views/loginPageStyle';
import { driverSignin, identify } from '../../requests/requests';
import catchingErr from '../../requests/requestErr';
import InputError from '../../components/InputError/InputError';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: 'cardHidden',
      email: '',
      password: '',
      snack: false,
      errTxt: '',
      goToDashboard: false
    };

    // this.checkLogin();
  }

  async componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: '' });
      }.bind(this),
      300
    );

    await this.checkLogin();
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  checkLogin = async () => {
    if (localStorage.getItem('token')) {
      identify()
        .then(res => {
          sessionStorage.setItem('user', JSON.stringify(res.data));
          this.setState(prevState => ({
            ...prevState,
            goToDashboard: true
          }));
        })
        .catch(err => {
          console.log(err);
          localStorage.removeItem('token');
        });
    }
  };

  openModal = () => {
    this.setState({ snack: true });
    setTimeout(this.closeModal, 3000);
  };

  closeModal = () => {
    this.setState({ snack: false });
  };

  goToDashboard = () => {
    this.setState({ goToDashboard: true });
  };

  goToDocuments = () => {
    this.setState({ goToDocuments: true });
  };

  goToLanding = text => {
    this.setState({ goToLanding: true, landTxt: text });
  };

  handleChange = evt => {
    this.setState({
      [evt.target.id]: evt.target.value
    });
  };

  handleSubmit = (values, { setSubmitting }) => {
    const { email, password } = values;
    const _this = this;

    driverSignin(email, password)
      .then(res => {
        localStorage.setItem('token', res.data.token);
        identify().then(resIdentify => {
          const user = resIdentify.data;
          sessionStorage.setItem('user', JSON.stringify(user));
          if (!user.driver) {
            // localStorage.removeItem('token');
            _this.setState({ errTxt: 'Usuário inválido' });
            _this.openModal();
          } // else if (user.driver.reproved_at != null) this.goToLanding('Conta não aprovada');
          else {
            _this.goToDashboard();
          }
          setSubmitting(false);
        });
      })
      .catch(err => {
        _this.setState({ errTxt: catchingErr(err) });
        _this.openModal();
        setSubmitting(false);
        localStorage.removeItem('token');
      });
  };

  render() {
    const { classes } = this.props;
    const { cardAnimaton, snack, errTxt, goToDashboard, forget } = this.state;

    if (goToDashboard) return <Redirect to="/driver-history" />;
    if (forget) return <Redirect to="/driver/recovery" />;
    if (localStorage.getItem('token')) return <div />;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <Snackbar
            style={{ marginTop: '0em' }}
            place="tc"
            color="danger"
            icon={Error}
            message={errTxt}
            open={snack}
          />
          <GridItem xs={12} sm={6} md={4}>
            <Formik
              initialValues={{ email: '', password: '' }}
              validate={values => {
                const errors = {};
                if (!values.email) {
                  errors.email = 'Campo obrigatório';
                } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                  errors.email = 'Email inválido';
                }
                if (!values.password) {
                  errors.password = 'Campo obrigatório';
                }
                return errors;
              }}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                errors,
                touched
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Card login className={classes[cardAnimaton]}>
                      <CardHeader
                        className={`${classes.cardHeader} ${classes.textCenter}`}
                        color="danger"
                      >
                        <h4 className={classes.cardTitle}>Acesso para motoristas</h4>
                      </CardHeader>
                      <CardBody>
                        <TextField
                          fullWidth
                          style={{ marginTop: 30 }}
                          id="email"
                          name="email"
                          placeholder="Email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur('email')}
                          error={errors.email && touched.email}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Email className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            )
                          }}
                        />
                        <ErrorMessage name="email">
                          {msg => <InputError message={msg} />}
                        </ErrorMessage>

                        <TextField
                          fullWidth
                          style={{ marginTop: 30 }}
                          id="password"
                          name="password"
                          type="password"
                          placeholder="Senha"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur('password')}
                          error={errors.password && touched.password}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                              </InputAdornment>
                            )
                          }}
                        />
                        <ErrorMessage name="password">
                          {msg => <InputError message={msg} />}
                        </ErrorMessage>
                        <div align="right">
                          <button
                            type="button"
                            onClick={() => {
                              this.setState({ forget: true });
                            }}
                            style={{
                              color: 'blue',
                              background: 'none !important',
                              border: 'none',
                              padding: '0 !important',
                              font: 'inherit',
                              cursor: 'pointer'
                            }}
                          >
                            <small>Esqueceu sua senha?</small>
                          </button>
                        </div>
                      </CardBody>
                      <CardFooter className={classes.justifyContentCenter}>
                        <Button
                          color="rose"
                          simple
                          size="lg"
                          block
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Entrar
                        </Button>
                      </CardFooter>
                    </Card>
                  </form>
                );
              }}
            </Formik>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(loginPageStyle)(LoginPage);
