import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import GoogleMapReact from 'google-map-react';
import { trackRide } from '../../requests/requests';
import Marker from '../../components/Marker/Marker';
import { K_SIZE } from '../../components/Marker/styles';

export default function Track({ zoom, ...props }) {
  const [ride, setRide] = useState({
    latitude: -10.9450479,
    longitude: -37.0632273,
    driver_name: ''
  });

  const [first, setFirst] = useState(true);
  const [error, setError] = useState();

  async function fetchData() {
    console.log('tick');
    try {
      const res = await trackRide(props.match.params.id);
      setRide(res.data);
      if (first) setFirst(false);
    } catch (e) {
      if (first) {
        window.location = 'https://www.beelog.app';
        setFirst(false);
      }
      setError(e);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!error) {
      let timeOutFunction = setTimeout(() => fetchData(), 5000);
      return function cleanup() {
        clearTimeout(timeOutFunction);
        timeOutFunction = null;
      };
    }
  }, [ride]);

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyA4pSDOrfS1tNeYkIBOcPZSuj4qePDys6w' }}
        defaultCenter={{ lat: -10.9450479, lng: -37.0632273 }}
        center={{ lat: ride.latitude, lng: ride.longitude }}
        defaultZoom={16}
        hoverDistance={K_SIZE / 2}
      >
        <Marker
          lat={ride.latitude}
          lng={ride.longitude}
          text={`${ride.driver_name} ${ride.plate} ${ride.vehicle_type === 0 ? 'Moto' : 'Carro'}`}
        />
      </GoogleMapReact>
    </div>
  );
}

Track.propTypes = {
  zoom: PropTypes.number
};
