import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import {
  Grid,
  Card,
  CardContent,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  withMobileDialog
} from '@material-ui/core';

import Assignment from '@material-ui/icons/Assignment';
import Search from '@material-ui/icons/Search';

import CardHeader from '../../components/Card/CardHeader';
import CardIcon from '../../components/Card/CardIcon';
import { driverTransfers } from '../../requests/requests';
import { formatTimeDate, translateTransferStatus, formatMoney } from '../../helpers/functions';

function DriverTransfers({ fullScreen }) {
  const [loading, setLoading] = useState(true);
  const [transfers, setTransfers] = useState([]);
  const [pages, setPages] = useState(0);
  const [modal, setModal] = useState({ status: false, transfer: {} });

  async function fetchTable(tableState) {
    setLoading(true);

    try {
      const res = await driverTransfers(tableState.page * tableState.pageSize, tableState.pageSize);
      setTransfers(
        res.data.cashouts
      );
      setLoading(false);
      setPages(parseInt(res.data.count / tableState.pageSize, 10));
    } catch (err) {
      alert('Dados indisponíveis');
      setLoading(false);
    }
  }

  return (
    <Grid container justify="center">
      <Grid item xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4>Corridas</h4>
          </CardHeader>
          <CardContent>
            <p>Repasses a partir de 01/05/2022.</p>
            <ReactTable
              className="-striped -highlight"
              data={transfers}
              sortable={false}
              defaultPageSize={10}
              pages={pages}
              loading={loading}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Nenhum dado encontrado"
              manual
              onFetchData={state => fetchTable(state)}
              columns={[
                {
                  id: 'colAmount',
                  Header: 'Valor',
                  accessor: row => `R$ ${formatMoney(Math.abs(row.amount))}`
                },
                {
                  id: 'colFee',
                  Header: 'Taxa',
                  accessor: row => row.fee ? `R$ ${formatMoney(row.fee)}` : 'R$0,00'
                },
                {
                  id: 'colDate',
                  Header: 'Data',
                  accessor: row => {
                    const array = formatTimeDate(row.created_at);
                    return `${array[0]}`;
                  }
                }               
              ]}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

DriverTransfers.propTypes = {
  fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog()(DriverTransfers);
