/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// @material-ui/icons
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import Edit from '@material-ui/icons/Edit';

import styles from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
// @material-ui/icons
import PermIdentity from '@material-ui/icons/PermIdentity';
import LocalAtm from '@material-ui/icons/LocalAtm';
import Settings from '@material-ui/icons/Settings';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
// core components
import { Formik, ErrorMessage } from 'formik';

import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import CustomInput from '../../components/CustomInput/CustomInput';
import Clearfix from '../../components/Clearfix/Clearfix';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import CardFooter from '../../components/Card/CardFooter';
import CardIcon from '../../components/Card/CardIcon';
import CardAvatar from '../../components/Card/CardAvatar';
import Button from '../../components/CustomButtons/Button';
import Snackbar from '../../components/Snackbar/Snackbar';

import userProfileStyles from '../../assets/jss/material-dashboard-pro-react/views/userProfileStyles';

import { formatTimeDate } from '../../helpers/functions';
import { driverSplitter, updateTransferSettings, bankAccount, getBanks, getBankAccount } from '../../requests/requests';
import {
  plateRegex,
  bankNumberRegex,
  bankBranchRegex,
  branchDigitRegex,
  bankAccountRegex,
  accountDigitRegex,
  bankVariationRegex
} from '../../helpers/regex';

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: JSON.parse(sessionStorage.getItem('user')),
      bankAccount: null,
      splitter: null,
      selectedEnabled: '2',
      txt: '',
      snack: false,
      snackColor: 'danger'
    };

    this.openSnack = this.openSnack.bind(this);
    this.handleChangeEnabled = this.handleChangeEnabled.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.showError = this.showError.bind(this);
    this.changeClick = this.changeClick.bind(this);
  }

  openSnack() {
    this.setState({ snack: true });
    setTimeout(() => this.setState({ snack: false }), 3000);
  }

  handleChange(evt) { //only for bankAccount
    this.setState({ bankAccount: { ...this.state.bankAccount, [evt.target.id]: evt.target.value} });
  }

  async componentDidMount() {
    try {
      const splitterRes = await getBankAccount();

      if (splitterRes.status === 200) {
        this.setState({
          bankAccount: splitterRes.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  showError(message){
    this.setState({
      txt: message,
      snackColor: 'danger'
    })
    this.openSnack();
  }

  changeClick() {
    console.log(this.state.bankAccount);

    if(!bankBranchRegex.test(this.state.bankAccount.agency)){
      this.showError("Agência inválida")
      return false;
    }

    if(!accountDigitRegex.test(this.state.bankAccount.digit)){
      this.showError("Dígito inválido")
      return false;
    }

    if(!bankAccountRegex.test(this.state.bankAccount.number)){
      this.showError("Número de conta inválido")
      return false;
    }

    if(this.state.bankAccount.variation != null && !bankVariationRegex.test(this.state.bankAccount.variation)){
      this.showError("Variação inválida")
      return false;
    }

    if(!bankNumberRegex.test(this.state.bankAccount.bank_number)){
      this.showError("Código do banco inválido")
      return false;
    }

    bankAccount(this.state.bankAccount.bank_number,
      this.state.bankAccount.agency,
      this.state.bankAccount.number,
      this.state.bankAccount.digit,
      this.state.bankAccount.variation,
      this.state.bankAccount.type,
      this.state.bankAccount.has_cpf_pix_key)
      .then(() =>
        this.setState({
          txt: 'Conta alterada com sucesso.',
          snackColor: 'success'
        })
      )
      .catch(() =>
        this.setState({
          txt: 'Erro ao alterar conta bancária.',
          snackColor: 'danger'
        })
      )
      .finally(() => this.openSnack());
  }

  handleChangeEnabled(event) {
    this.setState({ selectedEnabled: event.target.value });
  }

  render() {
    const { classes } = this.props;
    const { user, bankAccount, splitter, snack, snackColor, txt } = this.state;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <PermIdentity />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Perfil</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={8}>
                    <CustomInput
                      labelText="Nome"
                      id="name"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        defaultValue: user.name
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Telefone"
                      id="phone"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        defaultValue: user.phone
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={8}>
                    <CustomInput
                      labelText="Email"
                      id="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        defaultValue: user.email
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="CPF"
                      id="cpf"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        defaultValue: user.cpf
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem style={{ marginTop: 10, marginBotton: 7 }} xs={12} sm={12} md={12}>
                    <InputLabel style={{ color: '#AAAAAA' }}>Endereço</InputLabel>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={4}>
                    <CustomInput
                      labelText="Cidade"
                      id="city"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        defaultValue: user.address.city.nome
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={4}>
                    <CustomInput
                      labelText="Estado"
                      id="state"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        defaultValue: user.address.city.state.nome
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="CEP"
                      id="zipcode"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        defaultValue: user.address.zipcode
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    <CustomInput
                      labelText="Logradouro"
                      id="line1"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        defaultValue: user.address.line1
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <CustomInput
                      labelText="Complemento"
                      id="line2"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        defaultValue: user.address.line2
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    <CustomInput
                      labelText="Bairro"
                      id="district"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        defaultValue: user.address.district
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={3}>
                    <CustomInput
                      labelText="Número"
                      id="number"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        defaultValue: user.address.number
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <Clearfix />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card profile>
              {user.picture ? (
                <CardAvatar profile>
                  <img src={user.picture} alt="..." />
                </CardAvatar>
              ) : (
                <div />
              )}
              <CardBody profile>
                <h6 className={classes.cardCategory}>Veículo</h6>
                <h4 className={classes.cardTitle}>{user.driver.vehicle.plate}</h4>
                <p className={classes.description}>
                  {user.driver.vehicle.description}
                  <br />
                  {`Tipo: ${user.driver.vehicle.type.name} ${user.driver.vehicle.type.note || ''}`}
                </p>
              </CardBody>
              <CardFooter>
                <h6>{`Membro desde: ${formatTimeDate(user.signup_date)[0]}`}</h6>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        {bankAccount != null ? (
          <GridContainer>
            <GridItem xs={12} sm={12} md={8}>
              <Card>
                <CardHeader color="success" icon>
                  <CardIcon color="success">
                    <LocalAtm />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>Conta bancária</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={8}>
                      <CustomInput
                        labelText="Titular da conta"
                        id="holderName"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          defaultValue: bankAccount.holder_name
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="CPF/CNPJ"
                        id="taxDocument"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          defaultValue: bankAccount.document
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Código do Banco (Ex.: 001)"
                        id="bank_number"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: this.handleChange,
                          disabled: false,
                          defaultValue: bankAccount.bank_number
                        }}
                      />                      
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={6} sm={6} md={6}>
                      <CustomInput
                        labelText="Número da agência"
                        id="agency"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{                          
                          maxLength: 5,
                          disabled: false,
                          onChange: this.handleChange,
                          defaultValue: bankAccount.agency
                        }}
                      />
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4}>
                      <CustomInput
                        labelText="Número da conta"
                        id="number"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: this.handleChange,
                          defaultValue: bankAccount.number
                        }}
                      />
                    </GridItem>
                    <GridItem xs={2} sm={2} md={2}>
                      <CustomInput
                        labelText="Dígito"
                        id="digit"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: this.handleChange,
                          defaultValue: bankAccount.digit
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                                     
                    <GridItem xs={6} sm={6} md={6}>
                    <InputLabel htmlFor="type" className={'CustomInput-labelRoot-460'}>
                        Tipo de Conta
                      </InputLabel><br></br>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={this.state.bankAccount.type}
                        onChange={
                          evt => {
                            this.setState({ bankAccount: { ...this.state.bankAccount, type: evt.target.value} });                                      
                          }
                        }
                        inputProps={{
                          name: 'type'
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem
                          }}
                        >
                          Tipo de conta
                        </MenuItem>
                        <MenuItem
                          key={0}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={"CORRENTE"}
                        >
                          {"CORRENTE"}
                        </MenuItem>
                        <MenuItem
                          key={1}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={"POUPANCA"}
                        >
                          {"POUPANCA"}
                        </MenuItem>
                      </Select>
                    </GridItem>
                    
                      {this.state.bankAccount != null && this.state.bankAccount.type == "POUPANCA" ?
                      (
                        <GridItem xs={6} sm={6} md={6}>
                          <CustomInput
                            labelText="Variação"
                            id="variation"
                            formControlProps={{
                              fullWidth: true
                            }}                          
                            inputProps={{                            
                              onChange: this.handleChange,
                              defaultValue: bankAccount.variation
                            }}
                          />
                        </GridItem>
                      ) : ''}                                                                               
                  </GridContainer>
                  <br></br>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                    <Grid container alignItems="center" justify="flex-end">
                              <Grid item xs={2} sm={2} md={2}>
                                <Checkbox
                                  id="has_cpf_pix_key"
                                  checked={this.state.bankAccount.has_cpf_pix_key == 1}
                                  onChange={
                                    evt => {
                                      this.setState({ bankAccount: { ...this.state.bankAccount, [evt.target.id]: evt.target.checked ? 1 : 0} });                                      
                                    }
                                  }
                                  value={this.state.bankAccount.has_cpf_pix_key === 1}
                                />
                              </Grid>
                              <Grid item xs={10} sm={10} md={10}>
                                <span>
                                  Tenho chave PIX com o meu CPF e desejo receber na conta associada à mesma.
                                </span>
                              </Grid>
                            </Grid>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <Button color="primary" onClick={this.changeClick}>
                            Salvar
                      </Button>
                    </GridItem>       
                  </GridContainer>     
                  <Snackbar place="tc" color={snackColor} icon={Edit} message={txt} open={snack} close />             
                  <Clearfix />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        ) : (
          ''
        )}
        
      </div>
    );
  }
}

export default withStyles(styles)(UserProfile);
