import LoginPage from 'views/Pages/LoginPage';
import RegisterPage from 'views/Pages/RegisterPage';

// @material-ui/icons
import PersonAdd from '@material-ui/icons/PersonAdd';
import Fingerprint from '@material-ui/icons/Fingerprint';
import LockOpen from '@material-ui/icons/LockOpen';
import DriverDocs from '../views/Pages/DriverDocs';
import Landing from '../views/Pages/Landing';
import Forget from '../views/Pages/Forget';
import Terms from '../views/Pages/Terms';
import DriverForm from '../views/Pages/DriverForm';

const pagesRoutes = [
  {
    path: '/driver/register',
    name: 'Registrar',
    short: 'Registrar',
    mini: 'RP',
    icon: PersonAdd,
    component: RegisterPage
  },
  {
    path: '/driver/landing',
    name: 'Landing Page',
    short: 'Landing',
    mini: 'Ln',
    icon: PersonAdd,
    component: Landing,
    hidden: true
  },
  {
    path: '/driver/terms',
    name: 'Termos de compromisso',
    short: 'Termos',
    mini: 'TC',
    icon: Fingerprint,
    component: Terms,
    hidden: true
  },
  {
    path: '/driver/form',
    name: 'Informacoes cadastrais',
    short: 'Cadastro',
    mini: 'IC',
    icon: Fingerprint,
    component: DriverForm,
    hidden: true
  },
  {
    path: '/driver/documents',
    name: 'Envio de Documentos',
    short: 'Documentos',
    mini: 'DC',
    icon: PersonAdd,
    component: DriverDocs,
    hidden: true,
    locked: true,
    permissions: ['IS_DRIVER']
  },
  {
    path: '/driver/login',
    name: 'Entrar',
    short: 'Entrar',
    mini: 'LP',
    icon: Fingerprint,
    component: LoginPage
  },
  {
    path: '/driver/recovery',
    name: 'Forgot',
    short: 'Forgot',
    mini: 'FG',
    icon: LockOpen,
    component: Forget,
    hidden: true
  }
  // {
  //   redirect: true,
  //   path: '/driver',
  //   pathTo: '/driver/login',
  //   name: 'Entrar'
  // }
];

export default pagesRoutes;
