export const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

export const phoneRegex = /(?:^\([0]?[1-9]{2}\) |^[0]?[1-9]{2}[.-\s]?)[9]?[1-9]\d{3}[.-\s]?\d{4}$/;

export const plateRegex = /^[A-Z0-9]{7}/;

export const zipcodeRegex = /^[0-9]{8}$/;

export const bankAccountRegex = /^[0-9]{3,20}$/;

export const accountDigitRegex = /^([0-9]{1,2}|[xX]{1})$/;

export const bankBranchRegex = /^[0-9]{1,8}$/;

export const branchDigitRegex = /^[0-9]{2}$/;

export const bankVariationRegex = /^[0-9]{0,3}$/;

export const bankNumberRegex = /^[0-9]{1,3}$/;

export const numbersRegex = /^[0-9]*$/;

export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
