import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Divider, Grid } from '@material-ui/core';

// @material-ui/icons
import Location from '@material-ui/icons/LocationOn';
import City from '@material-ui/icons/LocationCity';
import Map from '@material-ui/icons/Map';
import DirectionsCar from '@material-ui/icons/DirectionsCar';
import Explorer from '@material-ui/icons/Explore';
import Description from '@material-ui/icons/Description';
import Error from '@material-ui/icons/Error';
import Home from '@material-ui/icons/Home';
import Person from '@material-ui/icons/Person';
import Wallet from '@material-ui/icons/AccountBalanceWallet';
import Domain from '@material-ui/icons/Domain';

// core components
import { Formik, ErrorMessage } from 'formik';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Button from '../../components/CustomButtons/Button';
import CustomInput from '../../components/CustomInput/CustomInput';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import Snackbar from '../../components/Snackbar/Snackbar';

import registerPageStyle from '../../assets/jss/material-dashboard-pro-react/views/registerPageStyle';
import customSelectStyle from '../../assets/jss/material-dashboard-pro-react/customSelectStyle';
import { getAddress, getCities, getStates, driverSignup, getBanks } from '../../requests/requests';
import {
  plateRegex,
  zipcodeRegex,
  bankBranchRegex,
  branchDigitRegex,
  bankAccountRegex,
  accountDigitRegex,
  bankVariationRegex
} from '../../helpers/regex';
import catchingErr from '../../requests/requestErr';
import { verifyCPF } from '../../helpers/functions';
import { CPFMaskCustom } from '../../components/CustomMasks/Masks';
import {
  validateRequired,
  validateRegex,
  validateMaxLength,
  validateNotSelected,
  validateCPF
} from '../../validators/validators';
import InputError from '../../components/InputError/InputError';

const styles = {
  ...registerPageStyle,
  ...customSelectStyle
};

class DriverForm extends React.Component {
  constructor(props) {
    super(props);
    const sessionState = JSON.parse(sessionStorage.getItem('driver-form'));
    if (sessionState) {
      this.state = sessionState;
      sessionStorage.removeItem('driver-form');
    } else {
      this.state = {
        plate: '',
        type: '',
        description: '',
        zipcode: '',
        cities: [],
        city: '',
        states: [],
        state: '',
        district: '',
        number: '',
        line1: '',
        line2: '',
        goToNext: false,
        goToLogin: false,
        snack: false,
        errTxt: '',
        holderName: '',
        taxDocument: '',
        bankNumber: '',
        accountNumber: '',
        accountNumberDV: '',
        bankBranchNumber: '',
        bankBranchNumberDV: '',
        variation: '',
        accountType: '',
        banks: []
      };
    }
  }

  componentDidMount() {
    getStates().then(res => {
      this.setState({
        states: res.data.estados
      });
    });

    getBanks().then(res => {
      this.setState({
        banks: res.data.banks
      });
    });
    // const state = JSON.parse(sessionStorage.getItem('driver-form'));
    // if (state) {
    //   await this.setState({ ...state });
    //   sessionStorage.removeItem('driver-form');
    // }
    // this.setState({ goToLogin: true });
    if (!this.props.location.state) this.setState({ goToLogin: true });
  }

  setCities(stateId) {
    getCities(stateId).then(res => {
      this.setState({
        cities: res.data.cidades
      });
    });
  }

  openModal = () => {
    this.setState({ snack: true });
    setTimeout(this.closeModal, 3000);
  };

  closeModal = () => {
    this.setState({ snack: false });
  };

  handleZipcode = (evt, regex) => {
    if (regex.test(evt.target.value)) {
      this.setState({
        [evt.target.id]: evt.target.value
      });

      if (evt.target.value.length !== 8) return;
      getAddress(evt.target.value).then(res => {
        const { bairro, logradouro, complemento } = res.data;

        this.setState({
          district: bairro,
          line1: logradouro,
          line2: complemento
        });
      });
    }
  };

  handleCPF = evt => {
    evt.persist();
    this.setState({
      [evt.target.id]: evt.target.value
    });
    if (!verifyCPF(evt.target.value.replace(/[.-]/g, '')))
      evt.target.setCustomValidity('CPF inválido');
    else evt.target.setCustomValidity('');
  };

  handleSelectState = evt => {
    this.setState({
      [evt.target.name]: evt.target.value
    });

    getCities(evt.target.value).then(res => {
      this.setState({
        cities: res.data.cidades
      });
    });
  };

  handleSelect = evt => {
    this.setState({
      [evt.target.name]: evt.target.value
    });
  };

  handleChange = (evt, regex = null) => {
    if (regex) {
      if (regex.test(evt.target.value)) {
        this.setState({
          [evt.target.id]: evt.target.value
        });
      }
    } else {
      this.setState({
        [evt.target.id]: evt.target.value
      });
    }
  };

  handlePlate = evt => {
    this.setState({
      plate: evt.target.value.toUpperCase()
    });
  };

  handleError = evt => {
    console.log(evt.target);

    // evt.target.id.array.foreach(id => {
    //   const doc = document.getElementById(id);
    //   if (doc.getAttribute('aria-invalid')) {
    //     doc.setAttribute('error', true);
    //   }
    // });
  };

  handleSubmit = async (values, { setSubmitting }) => {
    const { request } = this.props.location.state;
    const {
      plate,
      type,
      description,
      zipcode,
      district,
      number,
      line1,
      line2,
      city,
      holderName,
      taxDocument,
      bankNumber,
      accountNumber,
      accountNumberDV,
      bankBranchNumber,
      bankBranchNumberDV,
      variation,
      accountType
    } = values;

    const account = accountNumberDV ? `${accountNumber}-${accountNumberDV}` : accountNumber;

    const branch = bankBranchNumberDV
      ? `${bankBranchNumber}-${bankBranchNumberDV}`
      : bankBranchNumber;

    try {
      const res = await driverSignup(
        request.name,
        request.email,
        request.password,
        request.phone,
        request.cpf,
        type,
        description,
        plate,
        city,
        zipcode,
        district,
        number,
        line1,
        line2,
        holderName,
        taxDocument,
        bankNumber,
        account,
        branch,
        variation,
        accountType
      );
      localStorage.setItem('token', res.data.token);
      setSubmitting(false);
      this.setState({
        goToNext: true
      });
    } catch (err) {
      sessionStorage.setItem('driver-form', JSON.stringify({ ...this.state, ...values }));
      await this.setState({
        errTxt: catchingErr(err)
      });
      this.openModal();
      setSubmitting(false);
    }
  };

  render() {
    const { classes } = this.props;
    const {
      plate,
      type,
      description,
      zipcode,
      district,
      cities,
      city,
      states,
      state,
      number,
      line1,
      line2,
      goToNext,
      goToLogin,
      snack,
      errTxt,
      holderName,
      taxDocument,
      bankNumber,
      accountNumber,
      accountNumberDV,
      bankBranchNumber,
      bankBranchNumberDV,
      variation,
      accountType
    } = this.state;

    if (goToNext) {
      return <Redirect to="/driver-history" />;
    }

    if (goToLogin) {
      return <Redirect to="/driver/register" />;
    }

    async function setAddress(values, setFieldValue) {
      const res = await getAddress(values.zipcode);
      const { bairro, logradouro, complemento } = res.data;

      setFieldValue('district', bairro);
      setFieldValue('line1', logradouro);
      setFieldValue('line2', complemento);
    }

    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <Snackbar
            style={{ marginTop: '0em' }}
            place="tc"
            color="danger"
            icon={Error}
            message={errTxt}
            open={snack}
          />
          <GridItem xs={12} sm={12} md={10}>
            <Card className={classes.cardSignup}>
              <h2 className={classes.cardTitle}>Cadastro de motorista</h2>
              <CardBody>
                <Formik
                  initialValues={{
                    plate,
                    description,
                    type,
                    zipcode,
                    line1,
                    number,
                    line2,
                    district,
                    state,
                    city,
                    holderName,
                    bankNumber,
                    bankBranchNumber,
                    bankBranchNumberDV,
                    taxDocument,
                    accountNumber,
                    accountNumberDV,
                    variation,
                    accountType
                  }}
                  enableReinitialize
                  validate={values => {
                    const errors = {};

                    validateRequired(values, errors, 'plate');
                    if (errors.plate == null) validateRegex(values, errors, plateRegex, 'plate');

                    validateRequired(values, errors, 'description');
                    if (errors.description == null)
                      validateMaxLength(values, errors, 100, 'description');

                    validateNotSelected(values, errors, 'type');

                    validateRequired(values, errors, 'zipcode');
                    if (!errors.zipcode) {
                      validateRegex(values, errors, zipcodeRegex, 'zipcode');
                    }

                    validateRequired(values, errors, 'line1');
                    if (!errors.line1) validateMaxLength(values, errors, 300, 'line1');

                    validateMaxLength(values, errors, 300, 'line2');

                    validateRequired(values, errors, 'number');
                    if (!errors.number) validateMaxLength(values, errors, 10, 'number');

                    validateRequired(values, errors, 'district');
                    if (!errors.district) validateMaxLength(values, errors, 100, 'district');

                    validateNotSelected(values, errors, 'state');

                    validateNotSelected(values, errors, 'city');

                    validateRequired(values, errors, 'holderName');
                    if (!errors.holderName) validateMaxLength(values, errors, 30, 'holderName');

                    validateNotSelected(values, errors, 'bankNumber');

                    validateRequired(values, errors, 'bankBranchNumber');
                    if (!errors.bankBranchNumber)
                      validateRegex(values, errors, bankBranchRegex, 'bankBranchNumber');

                    validateRegex(values, errors, branchDigitRegex, 'bankBranchNumberDV');

                    validateRequired(values, errors, 'taxDocument');
                    if (!errors.taxDocument) validateCPF(values, errors, 'taxDocument');

                    validateRequired(values, errors, 'accountNumber');
                    if (!errors.accountNumber)
                      validateRegex(values, errors, bankAccountRegex, 'accountNumber');

                    validateRequired(values, errors, 'accountNumberDV');
                    if (!errors.accountNumberDV)
                      validateRegex(values, errors, accountDigitRegex, 'accountNumberDV');

                    validateRegex(values, errors, bankVariationRegex, 'variation');

                    validateNotSelected(values, errors, 'accountType');
                    return errors;
                  }}
                  onSubmit={this.handleSubmit}
                >
                  {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    errors,
                    setFieldValue
                  }) => {
                    return (
                      <form
                        className={classes.form}
                        style={{ display: 'contents' }}
                        onSubmit={handleSubmit}
                      >
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={8} md={5}>
                            <FormControl fullWidth required className={classes.selectFormControl}>
                              <InputLabel htmlFor="type" className={classes.selectLabel}>
                                Veículo
                              </InputLabel>
                              <Select
                                MenuProps={{
                                  className: classes.selectMenu
                                }}
                                classes={{
                                  select: classes.select
                                }}
                                value={values.type}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                inputProps={{
                                  name: 'type'
                                }}
                              >
                                <MenuItem
                                  disabled
                                  classes={{
                                    root: classes.selectMenuItem
                                  }}
                                >
                                  Tipo de veículo *
                                </MenuItem>
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={0}
                                >
                                  Moto
                                </MenuItem>
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={1}
                                >
                                  Carro
                                </MenuItem>
                              </Select>
                            </FormControl>
                            <ErrorMessage name="type">
                              {msg => <InputError message={msg} />}
                            </ErrorMessage>
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses
                              }}
                              inputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                  >
                                    <DirectionsCar className={classes.inputAdornmentIcon} />
                                  </InputAdornment>
                                ),
                                id: 'plate',
                                name: 'plate',
                                placeholder: 'Placa *',
                                value: values.plate,
                                onChange: handleChange,
                                onBlur: handleBlur
                              }}
                            />
                            <ErrorMessage name="plate">
                              {msg => <InputError message={msg} />}
                            </ErrorMessage>
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses
                              }}
                              inputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                  >
                                    <Description className={classes.inputAdornmentIcon} />
                                  </InputAdornment>
                                ),
                                id: 'description',
                                name: 'description',
                                placeholder: 'Modelo do veículo (Ex.: Renault Sandero) *',
                                value: values.description,
                                onChange: handleChange,
                                onBlur: handleBlur
                              }}
                            />
                            <ErrorMessage name="description">
                              {msg => <InputError message={msg} />}
                            </ErrorMessage>
                          </GridItem>
                          <GridItem xs={12} sm={8} md={5}>
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses
                              }}
                              inputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                  >
                                    <Explorer className={classes.inputAdornmentIcon} />
                                  </InputAdornment>
                                ),
                                id: 'zipcode',
                                name: 'zipcode',
                                placeholder: 'CEP *',
                                value: values.zipcode,
                                onChange: handleChange,
                                onBlur: async evt => {
                                  handleBlur(evt);
                                  if (!errors.zipcode) {
                                    await setAddress(values, setFieldValue);
                                  }
                                }
                              }}
                            />
                            <ErrorMessage name="zipcode">
                              {msg => <InputError message={msg} />}
                            </ErrorMessage>
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses
                              }}
                              inputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                  >
                                    <Location className={classes.inputAdornmentIcon}>
                                      lock_outline
                                    </Location>
                                  </InputAdornment>
                                ),
                                id: 'line1',
                                name: 'line1',
                                placeholder: 'Logradouro',
                                value: values.line1,
                                onChange: handleChange,
                                onBlur: handleBlur
                              }}
                            />
                            <ErrorMessage name="line1">
                              {msg => <InputError message={msg} />}
                            </ErrorMessage>
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses
                              }}
                              inputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                  >
                                    <Home className={classes.inputAdornmentIcon} />
                                  </InputAdornment>
                                ),
                                id: 'number',
                                name: 'number',
                                placeholder: 'Número',
                                value: values.number,
                                onChange: handleChange,
                                onBlur: handleBlur
                              }}
                            />
                            <ErrorMessage name="number">
                              {msg => <InputError message={msg} />}
                            </ErrorMessage>
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses
                              }}
                              inputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                  >
                                    <Map className={classes.inputAdornmentIcon}>lock_outline</Map>
                                  </InputAdornment>
                                ),
                                id: 'line2',
                                name: 'line2',
                                placeholder: 'Complemento',
                                value: values.line2,
                                onChange: handleChange,
                                onBlur: handleBlur
                              }}
                            />
                            <ErrorMessage name="line2">
                              {msg => <InputError message={msg} />}
                            </ErrorMessage>
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses
                              }}
                              inputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                  >
                                    <City className={classes.inputAdornmentIcon} />
                                  </InputAdornment>
                                ),
                                id: 'district',
                                name: 'district',
                                placeholder: 'Bairro',
                                value: values.district,
                                onChange: handleChange,
                                onBlur: handleBlur
                              }}
                            />
                            <ErrorMessage name="district">
                              {msg => <InputError message={msg} />}
                            </ErrorMessage>
                            <FormControl fullWidth required className={classes.selectFormControl}>
                              <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                                Estado
                              </InputLabel>
                              <Select
                                MenuProps={{
                                  className: classes.selectMenu
                                }}
                                classes={{
                                  select: classes.select
                                }}
                                value={values.state}
                                onChange={evt => {
                                  this.setCities(evt.target.value);
                                  handleChange(evt);
                                }}
                                onBlur={handleBlur}
                                inputProps={{
                                  name: 'state'
                                }}
                              >
                                <MenuItem
                                  disabled
                                  classes={{
                                    root: classes.selectMenuItem
                                  }}
                                >
                                  Unidade *
                                </MenuItem>
                                {states.map(value => {
                                  return (
                                    <MenuItem
                                      key={value.id}
                                      classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected
                                      }}
                                      value={value.id}
                                    >
                                      {value.nome}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            <ErrorMessage name="state">
                              {msg => <InputError message={msg} />}
                            </ErrorMessage>
                            <FormControl fullWidth required className={classes.selectFormControl}>
                              <InputLabel htmlFor="city" className={classes.selectLabel}>
                                Cidade
                              </InputLabel>
                              <Select
                                MenuProps={{
                                  className: classes.selectMenu
                                }}
                                classes={{
                                  select: classes.select
                                }}
                                value={values.city}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                inputProps={{
                                  id: 'city',
                                  name: 'city'
                                }}
                              >
                                <MenuItem
                                  disabled
                                  classes={{
                                    root: classes.selectMenuItem
                                  }}
                                >
                                  Cidades *
                                </MenuItem>
                                {cities.map(value => {
                                  return (
                                    <MenuItem
                                      key={value.id}
                                      classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected
                                      }}
                                      value={value.id}
                                    >
                                      {value.nome}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            <ErrorMessage name="city">
                              {msg => <InputError message={msg} />}
                            </ErrorMessage>
                          </GridItem>
                        </GridContainer>
                        <Divider variant="inset" style={{ margin: '30px' }} />
                        <Grid container spacing={16} justify="center">
                          <Grid item align="center" xs={10} md={10}>
                            <h3>Informações para recebimento</h3>
                          </Grid>
                          <Grid container item alignContent="flex-start" md={5}>
                            <Grid item xs={12} md={12}>
                              <CustomInput
                                formControlProps={{
                                  fullWidth: true,
                                  className: classes.customFormControlClasses
                                }}
                                inputProps={{
                                  startAdornment: (
                                    <InputAdornment
                                      position="start"
                                      className={classes.inputAdornment}
                                    >
                                      <Person className={classes.inputAdornmentIcon} />
                                    </InputAdornment>
                                  ),
                                  id: 'holderName',
                                  name: 'holderName',
                                  placeholder: 'Titular',
                                  value: values.holderName,
                                  onChange: handleChange,
                                  onBlur: handleBlur
                                }}
                              />
                              <ErrorMessage name="holderName">
                                {msg => <InputError message={msg} />}
                              </ErrorMessage>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <FormControl fullWidth required className={classes.selectFormControl}>
                                <InputLabel htmlFor="bankNumber" className={classes.selectLabel}>
                                  Banco
                                </InputLabel>
                                <Select
                                  MenuProps={{
                                    className: classes.selectMenu
                                  }}
                                  classes={{
                                    select: classes.select
                                  }}
                                  value={values.bankNumber}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  inputProps={{
                                    name: 'bankNumber'
                                  }}
                                >
                                  <MenuItem
                                    disabled
                                    classes={{
                                      root: classes.selectMenuItem
                                    }}
                                  >
                                    Banco *
                                  </MenuItem>
                                  {this.state.banks.map((bank, idx) => {
                                    return (
                                      <MenuItem
                                        key={idx}
                                        classes={{
                                          root: classes.selectMenuItem,
                                          selected: classes.selectMenuItemSelected
                                        }}
                                        value={bank.number}
                                      >
                                        {bank.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                              <ErrorMessage name="bankNumber">
                                {msg => <InputError message={msg} />}
                              </ErrorMessage>
                            </Grid>
                            <Grid container item spacing={8} md={12}>
                              <Grid item md={8}>
                                <CustomInput
                                  formControlProps={{
                                    fullWidth: true,
                                    className: classes.customFormControlClasses
                                  }}
                                  inputProps={{
                                    startAdornment: (
                                      <InputAdornment
                                        position="start"
                                        className={classes.inputAdornment}
                                      >
                                        <Domain className={classes.inputAdornmentIcon} />
                                      </InputAdornment>
                                    ),
                                    id: 'bankBranchNumber',
                                    name: 'bankBranchNumber',
                                    placeholder: 'Número da Agência',
                                    value: values.bankBranchNumber,
                                    onChange: handleChange,
                                    onBlur: handleBlur
                                  }}
                                />
                                <ErrorMessage name="bankBranchNumber">
                                  {msg => <InputError message={msg} />}
                                </ErrorMessage>
                              </Grid>
                              <Grid item md={4}>
                                <CustomInput
                                  formControlProps={{
                                    fullWidth: true,
                                    className: classes.customFormControlClasses
                                  }}
                                  inputProps={{
                                    startAdornment: (
                                      <InputAdornment
                                        position="start"
                                        className={classes.inputAdornment}
                                      >
                                        <Domain className={classes.inputAdornmentIcon} />
                                      </InputAdornment>
                                    ),
                                    id: 'bankBranchNumberDV',
                                    name: 'bankBranchNumberDV',
                                    placeholder: 'DV',
                                    value: values.bankBranchNumberDV,
                                    onChange: handleChange,
                                    onBlur: handleBlur
                                  }}
                                />
                                <ErrorMessage name="bankBranchNumberDV">
                                  {msg => <InputError message={msg} />}
                                </ErrorMessage>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid container item alignContent="flex-start" md={5}>
                            <Grid item xs={10} md={12}>
                              <CustomInput
                                formControlProps={{
                                  fullWidth: true,
                                  className: classes.customFormControlClasses
                                }}
                                inputProps={{
                                  startAdornment: (
                                    <InputAdornment
                                      position="start"
                                      className={classes.inputAdornment}
                                    >
                                      <Description className={classes.inputAdornmentIcon} />
                                    </InputAdornment>
                                  ),
                                  id: 'taxDocument',
                                  name: 'taxDocument',
                                  placeholder: 'CPF',
                                  value: values.taxDocument,
                                  onChange: handleChange,
                                  onBlur: handleBlur,
                                  inputComponent: CPFMaskCustom
                                }}
                              />
                              <ErrorMessage name="taxDocument">
                                {msg => <InputError message={msg} />}
                              </ErrorMessage>
                            </Grid>
                            <Grid container item spacing={8} md={12}>
                              <Grid item md={8}>
                                <CustomInput
                                  formControlProps={{
                                    fullWidth: true,
                                    className: classes.customFormControlClasses
                                  }}
                                  inputProps={{
                                    startAdornment: (
                                      <InputAdornment
                                        position="start"
                                        className={classes.inputAdornment}
                                      >
                                        <Wallet className={classes.inputAdornmentIcon} />
                                      </InputAdornment>
                                    ),
                                    id: 'accountNumber',
                                    name: 'accountNumber',
                                    placeholder: 'Número da Conta',
                                    value: values.accountNumber,
                                    onChange: handleChange,
                                    onBlur: handleBlur
                                  }}
                                />
                                <ErrorMessage name="accountNumber">
                                  {msg => <InputError message={msg} />}
                                </ErrorMessage>
                              </Grid>
                              <Grid item md={4}>
                                <CustomInput
                                  formControlProps={{
                                    fullWidth: true,
                                    className: classes.customFormControlClasses
                                  }}
                                  inputProps={{
                                    startAdornment: (
                                      <InputAdornment
                                        position="start"
                                        className={classes.inputAdornment}
                                      >
                                        <Wallet className={classes.inputAdornmentIcon} />
                                      </InputAdornment>
                                    ),
                                    id: 'accountNumberDV',
                                    name: 'accountNumberDV',
                                    placeholder: 'DV',
                                    value: values.accountNumberDV,
                                    onChange: handleChange,
                                    onBlur: handleBlur
                                  }}
                                />
                                <ErrorMessage name="accountNumberDV">
                                  {msg => <InputError message={msg} />}
                                </ErrorMessage>
                              </Grid>
                            </Grid>
                            <Grid item xs={10} md={12}>
                              <CustomInput
                                formControlProps={{
                                  fullWidth: true,
                                  className: classes.customFormControlClasses
                                }}
                                inputProps={{
                                  id: 'variation',
                                  name: 'variation',
                                  placeholder: 'Variação',
                                  value: values.variation,
                                  onChange: handleChange,
                                  onBlur: handleBlur
                                }}
                              />
                              <ErrorMessage name="variation">
                                {msg => <InputError message={msg} />}
                              </ErrorMessage>
                            </Grid>
                            <Grid item xs={10} md={12}>
                              <FormControl fullWidth required className={classes.selectFormControl}>
                                <InputLabel htmlFor="accountType" className={classes.selectLabel}>
                                  Conta
                                </InputLabel>
                                <Select
                                  MenuProps={{
                                    className: classes.selectMenu
                                  }}
                                  classes={{
                                    select: classes.select
                                  }}
                                  value={values.accountType}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  inputProps={{
                                    name: 'accountType'
                                  }}
                                >
                                  <MenuItem
                                    disabled
                                    classes={{
                                      root: classes.selectMenuItem
                                    }}
                                  >
                                    Tipo de conta *
                                  </MenuItem>
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected
                                    }}
                                    value="corrente"
                                  >
                                    Corrente
                                  </MenuItem>
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected
                                    }}
                                    value="poupanca"
                                  >
                                    Poupança
                                  </MenuItem>
                                </Select>
                              </FormControl>
                              <ErrorMessage name="accountType">
                                {msg => <InputError message={msg} />}
                              </ErrorMessage>
                            </Grid>
                          </Grid>
                        </Grid>

                        <GridContainer justify="center" style={{ marginTop: '30px' }}>
                          <Button round color="primary" type="submit" disabled={isSubmitting}>
                            Próximo
                          </Button>
                        </GridContainer>
                      </form>
                    );
                  }}
                </Formik>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

DriverForm.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DriverForm);
