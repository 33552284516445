import axios from 'axios';
import url from './url';

const baseUrl = url.url;

const token = () => {
  return localStorage.getItem('token');
};

function findGetParameter(parameterName) {
  let result = null;
  let tmp = [];
  const items = window.location.search.substr(1).split('&');
  for (let index = 0; index < items.length; index++) {
    tmp = items[index].split('=');
    if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
  }
  return result;
}

function identify() {
  if (localStorage.getItem('token') == null && findGetParameter('token') == null) {
    window.location.href = 'https://meu.beelog.app/register';
  } else if (findGetParameter('token') != null) {
    localStorage.setItem('token', findGetParameter('token'));
  }

  return axios({
    method: 'get',
    url: `${baseUrl}/account/identify`,
    headers: { Authorization: `Bearer ${token()}` }
  });
}

function getBankAccount() {
  
  return axios({
    method: 'get',
    url: `${baseUrl}/account/bank-account`,
    headers: { Authorization: `Bearer ${token()}` }
  });
}

function trackRide(id) {
  return axios({
    method: 'get',
    url: `${baseUrl}/ride/driver-location-for-share`,
    params: {
      ride_id: id
    }
  });
}

function validateEmail(email) {
  return axios({
    method: 'post',
    url: `${baseUrl}/account/validate-email`,
    data: { email }
  });
}

function updatePassword(password, newPassword) {
  return axios({
    method: 'post',
    url: `${baseUrl}/account/update-password`,
    headers: { Authorization: `Bearer ${token()}` },
    data: { password, newPassword }
  });
}

function updateTransferSettings(days) {
  return axios({
    method: 'get',
    url: `${baseUrl}/account/update-transfer-settings?days=${days}`,
    headers: { Authorization: `Bearer ${token()}` }
  });
}

function bankAccount(
  bank_number,
  agency,
  number,
  digit,
  variation,
  type,
  has_cpf_pix_key,
) {
  return axios({
    method: 'post',
    url: `${baseUrl}/account/update-bank-account`,
    data: { bank_number,
      agency,
      number,
      digit,
      variation,
      type,
      has_cpf_pix_key},
    headers: {
      Authorization: `Bearer ${token()}`
    }
  });
}

function driverSplitter(splitterId) {
  return axios({
    method: 'get',
    url: `${baseUrl}/account/splitter`,
    headers: {
      Authorization: `Bearer ${token()}`
    }
  });
}

function driverHistory(index = 0, length = 30) {
  return axios({
    method: 'get',
    url: `${baseUrl}/ride/driver-history`,
    headers: { Authorization: `Bearer ${token()}` },
    params: { index, length }
  });
}

function stats() {
  return axios({
    method: 'get',
    url: `${baseUrl}/account/stats`,
    headers: { Authorization: `Bearer ${token()}` }
  });
}

function balance() {
  return axios({
    method: 'get',
    url: `${baseUrl}/account/balance`,
    headers: { Authorization: `Bearer ${token()}` }
  });
}

export const driverDocuments = () => {
  return axios({
    method: 'get',
    url: `${baseUrl}/account/driver-documents`,
    headers: { Authorization: `Bearer ${token()}` }
  });
};

function driverTransfers(index = 0, length = 30) {
  return axios({
    method: 'post',
    url: `${baseUrl}/account/cashouts`,
    headers: {
      Authorization: `Bearer ${token()}`
    },
    data: { index, length }
  });
}

function driverSignin(email, password) {
  return axios({
    method: 'post',
    url: `${baseUrl}/account/signin`,
    data: { email, password }
  });
}

function driverSignup(
  name,
  email,
  password,
  phone,
  cpf,
  type,
  description,
  plate,
  cityId,
  zipcode,
  district,
  number,
  line1,
  line2,
  holderName,
  taxDocument,
  bankNumber,
  accountNumber,
  bankBranchNumber,
  variation,
  accountType
) {
  return axios({
    method: 'post',
    url: `${baseUrl}/account/driver-signup`,
    data: {
      name,
      email,
      password,
      phone,
      cpf,
      vehicle: {
        plate,
        type,
        description
      },
      address: {
        city_id: cityId,
        zipcode,
        district,
        number,
        line1,
        line2
      },
      bankAccount: {
        holderName,
        taxDocument,
        bankNumber,
        accountNumber,
        bankBranchNumber,
        variation,
        type: accountType
      }
    }
  });
}

function getAddress(zipcode) {
  return axios({
    method: 'get',
    url: `https://viacep.com.br/ws/${zipcode}/json/`
  });
}

function getCities(stateId) {
  return axios({
    method: 'get',
    params: { state_id: stateId },
    url: `${baseUrl}/account/cities`
  });
}

function getStates() {
  return axios({
    method: 'get',
    baseURL: `${baseUrl}/account/states`
  });
}

function getBanks() {
  return axios({
    method: 'get',
    baseURL: `https://brasilapi.com.br/api/banks/v1`
  });
}

async function sendImage(request, file) {
  const formData = new FormData();
  formData.set('image', file);
  let requisition;

  switch (request) {
    case 'profile':
      requisition = 'user-image';
      break;
    case 'crlv':
      requisition = 'driver-vehicle-document';
      break;
    case 'records':
      requisition = 'driver-criminal-records';
      break;
    default:
      requisition = `driver-${request}`;
      break;
  }

  const Url = `${baseUrl}/account/${requisition}`;
  return axios({
    method: 'post',
    headers: {
      Authorization: `Bearer ${token()}`
    },
    data: formData,
    baseURL: Url
  });
}
/**
 *
 *
 * @param {String} rideId the ride id
 * @returns url to image
 */
function getRideMap(rideId) {
  return `https://s3.us-east-2.amazonaws.com/beelog/dev/ride-images/${rideId}.png`;
}

export {
  identify,
  validateEmail,
  trackRide,
  updatePassword,
  bankAccount,
  driverSplitter,
  driverHistory,
  driverTransfers,
  driverSignin,
  driverSignup,
  getAddress,
  getStates,
  getBankAccount,
  getBanks,
  getCities,
  sendImage,
  getRideMap,
  updateTransferSettings,
  stats,
  balance
};
