import React from 'react';
// import { withStyles } from '@material-ui/core';
import { greatPlaceStyle } from './styles';

export default function Marker(props) {
  const { text } = props;

  const style = greatPlaceStyle;

  return <div style={{ ...style, border: '5px solid #00FF00' }}>{text}</div>;
}
