/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/core/Icon';
import Warning from '@material-ui/icons/Warning';
import Money from '@material-ui/icons/Money';
import Star from '@material-ui/icons/Star';
import History from '@material-ui/icons/History';
import Map from '@material-ui/icons/Map';
import DateRange from '@material-ui/icons/DateRange';

import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Danger from '../../components/Typography/Danger';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardIcon from '../../components/Card/CardIcon';
import CardBody from '../../components/Card/CardBody';
import CardFooter from '../../components/Card/CardFooter';

import { formatTimeDate, formatMoney } from '../../helpers/functions';
import { balance, stats } from '../../requests/requests';

class DriverDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stats: null,
      balance: null
    };
  }

  async componentDidMount() {
    try {
      const statsRes = await stats();

      if (statsRes.status === 200) {
        this.setState({
          stats: statsRes.data
        });
      }
    } catch (error) {
      console.log(error);
    }

    try {
      const balanceRes = await balance();

      if (balanceRes.status === 200) {
        this.setState({
          balance: balanceRes.data
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const { classes } = this.props;
    const { stats, balance } = this.state;

    return (
      <GridContainer justify="left">
        {balance != null ? (
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <Money />
                </CardIcon>
                <p className={classes.cardCategory}>Saldo atual</p>
                <h3 className={classes.cardTitle}>
                  <small>R$</small> {formatMoney(Math.max(0, balance.availableBalance))}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <p>
                    <b>
                      Transferência para conta bancária toda quarta-feira caso o saldo seja maior que R$30,00.                      
                    </b>
                  </p>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        ) : (
          ''
        )}
        {stats != null ? (
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <History />
                </CardIcon>
                <p className={classes.cardCategory}>Corridas realizadas</p>
                <h3 className={classes.cardTitle}>{stats.numRides}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <DateRange />
                  Desde o início
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        ) : (
          ''
        )}
        {stats != null ? (
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <Map />
                </CardIcon>
                <p className={classes.cardCategory}>KM Rodados</p>
                <h3 className={classes.cardTitle}>
                  <small>Aprox. </small> {formatMoney(stats.avgDistance / 1000)}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <DateRange />
                  Desde o início
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        ) : (
          ''
        )}
        {stats != null && stats.ratePastWeek != null ? (
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <Star />
                </CardIcon>
                <p className={classes.cardCategory}>Nota</p>
                <h3 className={classes.cardTitle}>
                  <small>Aprox. </small> {formatMoney(stats.ratePastWeek)}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <DateRange />
                  Dados contabilizados de acordo com os fretes realizados na semana anterior.
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        ) : (
          ''
        )}
      </GridContainer>
    );
  }
}

DriverDashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(DriverDashboard);
