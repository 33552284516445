import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox';
import Icon from '@material-ui/core/Icon';

// @material-ui/icons
import Timeline from '@material-ui/icons/Timeline';
import Code from '@material-ui/icons/Code';
import Group from '@material-ui/icons/Group';
import Face from '@material-ui/icons/Face';
import Phone from '@material-ui/icons/Phone';
import Email from '@material-ui/icons/Email';
import Slide from '@material-ui/core/Slide';
import Description from '@material-ui/icons/Description';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  TextField
} from '@material-ui/core';

// core components
import { Formik, ErrorMessage } from 'formik';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Button from '../../components/CustomButtons/Button';
import InfoArea from '../../components/InfoArea/InfoArea';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';

import registerPageStyle from '../../assets/jss/material-dashboard-pro-react/views/registerPageStyle';

import { PhoneMaskCustom, CPFMaskCustom } from '../../components/CustomMasks/Masks';
import { emailRegex, phoneRegex, passwordRegex } from '../../helpers/regex';
import { verifyCPF } from '../../helpers/functions';
import { validateEmail, identify } from '../../requests/requests';
import InputError from '../../components/InputError/InputError';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      name: '',
      email: '',
      password: '',
      phone: '',
      cpf: '',
      confirmPassword: '',
      errEmail: false,
      goToNext: false,
      terms: false,
      isTerms: false,
      goToDashboard: false
    };
    this.handleToggle = this.handleToggle.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  async componentDidMount() {
    await this.checkLogin();
    const request = JSON.parse(sessionStorage.getItem('request'));
    if (request) {
      const { name, phone, email, cpf } = request;
      await this.setState({
        name,
        phone,
        email,
        cpf
      });
      sessionStorage.removeItem('request');
    }
  }

  checkLogin = async () => {
    if (localStorage.getItem('token')) {
      identify()
        .then(res => {
          sessionStorage.setItem('user', JSON.stringify(res.data));
          this.setState(prevState => ({
            ...prevState,
            goToDashboard: true
          }));
        })
        .catch(err => {
          console.log(err);
          localStorage.removeItem('token');
        });
    }
  };

  handleSubmit = async (values, { setSubmitting, setErrors }) => {
    const { name, phone, email, password, cpf } = values;

    try {
      await validateEmail(email);
    } catch (error) {
      setErrors({ email: 'Email já cadastrado ou não existe' });
      setSubmitting(false);
      return;
    }

    setSubmitting(false);
    this.props.history.push('/driver/register');
    await this.setState({
      name,
      phone,
      email,
      password,
      cpf,
      goToNext: true
    });
  };

  handleToggle = event => {
    const value = event.target.checked;
    this.setState({
      [event.target.id]: value
    });
  };

  openModal(modal) {
    this.setState({ [modal]: true });
  }

  closeModal(modal) {
    this.setState({ [modal]: false });
  }

  render() {
    const { classes } = this.props;
    const { password, confirmPassword, name, phone, email, cpf, goToNext, isTerms } = this.state;

    if (localStorage.getItem('token')) {
      return <Redirect to="/driver-history" />;
    }

    if (goToNext) {
      const request = {
        name,
        phone,
        email,
        password,
        cpf
      };
      sessionStorage.setItem('request', JSON.stringify({ ...request, password: null }));
      return <Redirect to={{ pathname: '/driver/terms', state: { request } }} />;
    }

    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card className={classes.cardSignup}>
              <h2 className={classes.cardTitle}>Cadastro de Motorista</h2>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={6} md={5}>
                    <InfoArea
                      title="Marketing"
                      description="We've created the marketing campaign of the website. It was a very interesting collaboration."
                      icon={Timeline}
                      iconColor="rose"
                    />
                    <InfoArea
                      title="Fully Coded in HTML5"
                      description="We've developed the website with HTML5 and CSS3. The client has access to the code using GitHub."
                      icon={Code}
                      iconColor="primary"
                    />
                    <InfoArea
                      title="Built Audience"
                      description="There is also a Fully Customizable CMS Admin Dashboard for this product."
                      icon={Group}
                      iconColor="info"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={5}>
                    <Formik
                      enableReinitialize
                      initialValues={{
                        name,
                        phone,
                        cpf,
                        email,
                        password,
                        confirmPassword
                      }}
                      validate={values => {
                        const errors = {};
                        if (!values.name) {
                          errors.name = 'Campo Obrigatório';
                        } else if (values.name.lenght > 100) {
                          errors.name = 'Máximo 100 caracteres';
                        }

                        if (!values.phone) {
                          errors.phone = 'Campo Obrigatório';
                        } else if (!phoneRegex.test(values.phone)) {
                          errors.phone = 'Número inválido';
                        }

                        if (!values.cpf) {
                          errors.cpf = 'Campo Obrigatório';
                        } else if (!verifyCPF(values.cpf.replace(/[.-]/g, ''))) {
                          errors.cpf = 'CPF Inválido';
                        }

                        if (!values.email) {
                          errors.email = 'Campo Obrigatório';
                        } else if (!emailRegex.test(values.email)) {
                          errors.email = 'Email inválido';
                        } else if (values.email.lenght > 100) {
                          errors.email = 'Máximo 100 caracteres';
                        }

                        if (!values.password) {
                          errors.password = 'Campo Obrigatório';
                        } else if (!passwordRegex.test(values.password)) {
                          errors.password =
                            'Mínimo de 8 caracteres, pelo menos uma letra e um número';
                        } else if (values.password.lenght > 64) {
                          errors.password = 'Máximo de 64 caracteres';
                        }

                        if (values.password !== values.confirmPassword) {
                          errors.confirmPassword = 'Campo inválido';
                        }
                        return errors;
                      }}
                      onSubmit={this.handleSubmit}
                    >
                      {({
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        errors,
                        touched
                      }) => {
                        return (
                          <form onSubmit={handleSubmit}>
                            <TextField
                              fullWidth
                              style={{ marginTop: 30 }}
                              id="name"
                              name="name"
                              placeholder="Nome"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.name && touched.name}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                  >
                                    <Face className={classes.inputAdornmentIcon} />
                                  </InputAdornment>
                                )
                              }}
                            />
                            <ErrorMessage name="name">
                              {msg => <InputError message={msg} />}
                            </ErrorMessage>
                            <TextField
                              fullWidth
                              style={{ marginTop: 30 }}
                              id="phone"
                              name="phone"
                              placeholder="Telefone"
                              value={values.phone}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.phone && touched.phone}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                  >
                                    <Phone className={classes.inputAdornmentIcon} />
                                  </InputAdornment>
                                ),
                                inputComponent: PhoneMaskCustom
                              }}
                            />
                            <ErrorMessage name="phone">
                              {msg => <InputError message={msg} />}
                            </ErrorMessage>
                            <TextField
                              fullWidth
                              style={{ marginTop: 30 }}
                              id="cpf"
                              name="cpf"
                              placeholder="CPF"
                              value={values.cpf}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.cpf && touched.cpf}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                  >
                                    <Description className={classes.inputAdornmentIcon} />
                                  </InputAdornment>
                                ),
                                inputComponent: CPFMaskCustom
                              }}
                            />
                            <ErrorMessage name="cpf">
                              {msg => <InputError message={msg} />}
                            </ErrorMessage>
                            <TextField
                              fullWidth
                              style={{ marginTop: 30 }}
                              id="email"
                              name="email"
                              placeholder="Email"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.email && touched.email}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                  >
                                    <Email className={classes.inputAdornmentIcon} />
                                  </InputAdornment>
                                )
                              }}
                            />
                            <ErrorMessage name="email">
                              {msg => <InputError message={msg} />}
                            </ErrorMessage>
                            <TextField
                              fullWidth
                              style={{ marginTop: 30 }}
                              id="password"
                              name="password"
                              type="password"
                              placeholder="Senha"
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.password && touched.password}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                  >
                                    <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                                  </InputAdornment>
                                )
                              }}
                            />
                            <ErrorMessage name="password">
                              {msg => <InputError message={msg} />}
                            </ErrorMessage>
                            <TextField
                              fullWidth
                              style={{ marginTop: 30 }}
                              id="confirmPassword"
                              name="confirmPassword"
                              type="password"
                              placeholder="Confirmar Senha"
                              value={values.confirmPassword}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.confirmPassword && touched.confirmPassword}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                  >
                                    <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                                  </InputAdornment>
                                )
                              }}
                            />
                            <ErrorMessage name="confirmPassword">
                              {msg => <InputError message={msg} />}
                            </ErrorMessage>
                            <Grid container alignItems="center" justify="flex-end">
                              <Grid item xs={2} sm={2} md={2}>
                                <Checkbox
                                  id="isTerms"
                                  checked={isTerms}
                                  onChange={this.handleToggle}
                                  value="isTerms"
                                />
                              </Grid>
                              <Grid item xs={10} sm={10} md={10}>
                                <span>
                                  Concordo com os{' '}
                                  <button
                                    type="button"
                                    style={{
                                      background: 'none',
                                      border: 'none',
                                      margin: 0,
                                      padding: 0,
                                      cursor: 'pointer',
                                      color: 'purple'
                                    }}
                                    onClick={evt => {
                                      evt.preventDefault();
                                      this.openModal('terms');
                                    }}
                                  >
                                    {' '}
                                    termos e condições.
                                  </button>
                                </span>
                              </Grid>
                            </Grid>
                            <div className={classes.center}>
                              <Button
                                disabled={isSubmitting || !isTerms}
                                round
                                color="primary"
                                type="submit"
                                // onClick={handleSubmit}
                              >
                                Registrar
                              </Button>
                            </div>
                          </form>
                        );
                      }}
                    </Formik>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            <Dialog
              open={this.state.terms}
              TransitionComponent={Transition}
              keepMounted
              onClose={evt => {
                evt.preventDefault();
                this.closeModal('terms');
              }}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
              scroll="paper"
            >
              <DialogTitle id="alert-dialog-slide-title">Termos de compromisso</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tenetur obcaecati veniam
                  deleniti expedita nostrum itaque officia dolore animi pariatur repellendus vitae
                  beatae fugiat tempore, corporis architecto eum ullam eos ducimus.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={evt => {
                    evt.preventDefault();
                    this.closeModal('terms');
                  }}
                  color="primary"
                >
                  Fechar
                </Button>
              </DialogActions>
            </Dialog>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

RegisterPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(registerPageStyle)(RegisterPage);
