import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';

import registerPageStyle from '../../assets/jss/material-dashboard-pro-react/views/registerPageStyle';

class Landing extends React.Component {
  render() {
    const { classes, txt, location } = this.props;

    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card className={classes.cardSignup}>
              <h2 className={classes.cardTitle}>{txt || location.state.txt}</h2>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={10} align="justify">
                    <p>
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Impedit voluptas
                      quisquam, aliquid sequi sapiente eaque blanditiis dolor! Accusantium,
                      necessitatibus, molestiae facere eveniet id, sequi at ducimus sed eligendi
                      exercitationem odio?
                    </p>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Landing.propTypes = {
  classes: PropTypes.object.isRequired,
  txt: PropTypes.string,
  location: PropTypes.object
};

export default withStyles(registerPageStyle)(Landing);
